*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Space Grotesk', sans-serif;
    color: black;
}

button {
    cursor: pointer;
    font-family: 'Space Grotesk', sans-serif;

    &:disabled {
        cursor: auto;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

p {
    margin: 0;
}

a {
    text-decoration: none;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

hr {
    border: none;
    margin: 0;
}

u {
    text-underline-position: from-font;
}

picture {
    line-height: 0;
    display: block;

    img {
        width: 100%;
    }
}
