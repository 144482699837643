@use "/website/assets/scss/utilities/variables" as var;

$button-transition-property: background color;

.btn {
    cursor: pointer;
    outline: none;

    display: inline-block;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;

    padding: 12px 24px;
    margin: 0;

    color: var.$white;
    background: var.$black;
    border: none;
    border-radius: 48px;

    transition-property: $button-transition-property;
    transition-timing-function: ease-in-out;
    transition-duration: 150ms;

    &::after,
    &::before {
        transition-property: $button-transition-property;
        transition-timing-function: ease-in-out;
        transition-duration: 150ms;
    }

    &:hover,
    &:focus {
        background: var.$electric-blue;
    }

    &:disabled,
    &.disabled {
        cursor: default;
        color: var.$sky-dark;
        background: var.$sky-light;

        &:hover {
            background: var.$sky-light;
        }
    }
}

.btn.btn--inverted-hover {
    &:hover,
    &:focus {
        color: var.$black;
        background: var.$white;
    }
}

.btn.btn--lg {
    padding: 20px 32px;
}

.btn.btn--block {
    width: 100%;
    padding: 20px;
}

.btn.btn--outline {
    position: relative;
    background: none;
    color: var.$black;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 2px solid var.$black;
        border-radius: 48px;

        transition-property: $button-transition-property;
        transition-timing-function: ease-in-out;
        transition-duration: 150ms;
    }

    &:hover,
    &:focus {
        color: var.$electric-blue;

        &::after {
            border-color: var.$electric-blue;
        }
    }
}

.btn.btn--sm {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;

    text-align: center;
    letter-spacing: -0.01em;

    //text-decoration: underline;
    //text-decoration-width: 2px;
    //text-underline-position: from-font;

    background: rgba(0, 0, 0, 0);
    color: var.$black;
    padding: 8px 16px;

    &::after {
        content: '';
        position: absolute;
        bottom: #{8px - 2px};
        left: 16px;
        right: 16px;

        height: 2px;
        background: var.$black;
    }

    &:hover,
    &:focus {
        color: var.$white;
        background: var.$electric-blue;

        &::after {
            background: var.$white;
        }
    }
}

.btn-link {
    position: relative;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: var.$black;

    padding: 8px 16px;

    transition: color 300ms ease-in-out;
    outline: none;

    &::after {
        content: '';
        position: absolute;
        bottom: 7px;
        left: 16px;
        right: 16px;

        height: 1px;
        background: var.$black;

        transition: background 300ms ease-in-out;
    }

    &:hover,
    &:focus {
        color: var.$electric-blue;

        &::after {
            background: var.$electric-blue;
        }
    }
}

.btn-link.btn-link--inline {
    padding: 0;

    &::after {
        left: 0;
        bottom: -1px;
        right: 0;
    }
}
