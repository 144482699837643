@use "/website/assets/scss/utilities/variables" as var;

@mixin text-nowrap {
    overflow: hidden;
    white-space: nowrap;
}

//Fonts
@mixin font-headlines-h1() {
    //styleName: Mobile/Headlines/H1 - 48pt Bold;
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0;

    @media (min-width: map-get(var.$grid-breakpoints, 'md')) {
        //styleName: Desktop/Headlines/H1 - 64pt Bold;
        font-size: 64px;
        line-height: 68px;
        letter-spacing: -1px;
    }
}

@mixin font-headlines-h2() {
    @include font-mobile-headlines-h2();

    @media (min-width: map-get(var.$grid-breakpoints, 'md')) {
        @include font-desktop-headlines-h2();
    }
}

@mixin font-mobile-headlines-h2() {
    //styleName: Mobile/Headlines/H2 - 32pt Bold;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -1px;
}

@mixin font-desktop-headlines-h2() {
    //styleName: Desktop/Headlines/H2 - 56pt Bold;
    font-size: 56px;
    line-height: 60px;
}

@mixin font-headlines-h3() {
    @include font-mobile-headlines-h3();

    @media (min-width: map-get(var.$grid-breakpoints, 'md')) {
        @include font-desktop-headlines-h3();
    }
}

@mixin font-mobile-headlines-h3() {
    //styleName: Mobile/Headlines/H3 - 30pt Bold;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.5px;
}

@mixin font-desktop-headlines-h3() {
    //styleName: Desktop/Headlines/H3 - 48pt Bold;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: -0.5px;
}

@mixin font-headlines-h4-28 {
    //styleName: Mobile/Headlines/H4 - 28pt Bold;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.5px;
}

@mixin font-headlines-h4() {
    @include font-headlines-h4-28();

    @media (min-width: map-get(var.$grid-breakpoints, 'md')) {
        //styleName: Desktop/Headlines/H4 - 32pt Bold;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -1px;
    }
}


@mixin font-title-m() {
    //styleName: Desktop/Titles-Subtitles/Title M - 24pt Bold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
}

@mixin font-title-m-18() {
    //styleName: Mobile/Titles-Subtitles/Title M - 18pt Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0;
}

@mixin font-title-s() {
    //styleName: Desktop/Titles-Subtitles/Title S - 18pt Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0;
}

@mixin font-subtitle-m() {
    //styleName: Mobile/Titles-Subtitles/Subtitle Label - 12 Medium -Uppercase;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1px;

    //@media (min-width: var.$breakpoint-rule-tablet) {
    //    //styleName: Desktop/Titles-Subtitles/Subtitle Label - 12 Medium -Uppercase;
    //    font-size: 12px;
    //    font-style: normal;
    //    font-weight: 400;
    //    line-height: 16px;
    //    letter-spacing: 1px;
    //}
}


@mixin font-desktop-body-l() {
    //styleName: Desktop/Body/Body L - 24pt Regular;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0;
}

@mixin font-mobile-body-m() {
    //styleName: Mobile/Body/Body M - 18pt Regular;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
}

@mixin font-desktop-body-m() {
    //styleName: Desktop/Body/Body M- 18pt Regular;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
}

@mixin font-body-s() {
    //styleName: Mobile/Body/Body S - 16pt Regular;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
}

@mixin font-body-xs() {
    //styleName: Mobile/Body/Body XS - 14pt Regular;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
}


@mixin font-detail-regular() {
    //styleName: Desktop/Detail/Detail - 12pt Regular;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: 0;
}

@mixin font-detail-medium() {
    //styleName: Detail/D1 - 14pt Medium;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
}

@mixin font-input-textlink() {
    //styleName: CTAs - Inputs/Textlink - 16pt Bold U;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
}


@mixin legal-paragraph() {
    margin-bottom: 1.65em;
    line-height: 1.5;
}