@import "base/reboot";
@import "base/global";

//Components
@import "symbols/buttons";
@import "symbols/tabs";
@import "components/header";
@import "components/footer";
@import "components/modal";

.main-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__content {
        flex-grow: 1;
    }
}
