@use "/website/assets/scss/utilities/variables" as var;

.modal-backdrop-target {
    position: relative;

    &::before {
        content: '';
        z-index: var.$zindex-modal-backdrop;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        visibility: hidden;
        opacity: 0;
        background: var.$black;
        transition:
            opacity 500ms ease-in-out,
            visibility 500ms 0ms linear;
    }
}

.modal-backdrop-target.modal-backdrop-target--active {
    overflow: hidden;
    z-index: -1;

    &::before {
        visibility: visible;
        opacity: .5;
    }
}
