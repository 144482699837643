@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

.tabs {
    color: var.$black;

    &__link {
        color: inherit;
        margin-right: 12px;

        &:last-of-type {
            margin: 0;
        }
    }
}

.tab-link {
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    padding: 8px 12px;
    border-radius: 90px;
    @include mix.text-nowrap();
}

.tab-link.tab-link--active {
    border: 2px solid var.$electric-blue;
}

.tabs.tabs--fluid {
    display: flex;
    justify-content: space-between;
}

@media (min-width: map-get(var.$grid-breakpoints, 'md')) {
    .tabs {
        &__link {
            font-size: 16px;
            line-height: 18px;
            padding: 12px;
            margin-right: 40px;
        }
    }
}
