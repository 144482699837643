@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

$header-padding-x: 15px;
$header-hr-height: 2px;

.main-header-container {
    position: relative;
    background: var.$electric-blue;
}

.main-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    padding-top: 16px;
    padding-bottom: 16px;

    &__logo {
        order: 1;
        width: 152px;
        height: 35px;
        margin: 2px auto -2px -4px;
    }

    &__user {
        order: 2;

        .btn {
            background: var.$white;
            color: var.$electric-blue;
            padding: 10px 22px;

            &:hover,
            &:focus {
                background: var.$black;
                color: var.$white;
            }
        }
    }

    &__tabs {
        order: 3;
        width: 100%;
        color: var.$white;
        margin-top: 15px;
    }

    &__hr {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        height: $header-hr-height;
        margin: 0;
        border: none;
        border-radius: 5px;
        background: rgba(255, 255, 255, .3);
    }
}

.btn-dashboard {
    display: inline-block;
    color: var.$white;
    @include mix.font-detail-medium();

    svg {
        margin-left: 12px;
        vertical-align: middle;
    }
}

.main-header-container.main-header-container--white {
    background: var.$white;

    .main-header {

        &__tabs {
            color: var.$black;
        }

        &__user {
            .btn {
                color: var.$white;
                background: var.$electric-blue;

                &:hover,
                &:focus {
                    background: var.$black;
                }
            }
        }

    }

    .btn-dashboard {
        color: var.$black;
    }
}

@media (min-width: var.$breakpoint-rule-desktop) {
    .main-header {
        display: flex;
        align-items: center;
        padding: 19px 0;

        &__logo {
            width: auto;
            height: auto;
            margin: 4px auto -4px -7px;
        }

        &__tabs {
            order: 2;
            width: auto;
            margin: 0 40px 0 0;
        }

        &__user {
            order: 3;

            .btn {
                padding: 12px 22px;
            }
        }

        &__hr {
            display: none;
        }
    }
}

@media (max-width: var.$breakpoint-rule-gulliver) {
    .main-header {
        &__logo {
            width: 139px;
            height: 35px;
            margin: 2px auto -3px -4px;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__tabs {
            .tab-link {
                padding: 8px 0;
            }
        }
    }
}
