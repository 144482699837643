@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

@import "modal-backdrop";

.modal {
    display: none;
    flex-direction: column;
    z-index: var.$zindex-modal;
    position: fixed;
    bottom: 0;
    left: 50%;

    width: 100vw;
    max-width: 100vw;
    max-height: 67vh;
    padding: 0 15px;

    background: var.$white;
    border: 1px solid #E6E6E6;
    border-radius: 20px 20px 0 0;


    //opacity: 0;
    transform: translateX(-50%) translateY(100%);
    transition-property: opacity, transform;
    transition-duration: 350ms;
    transition-timing-function: ease-in-out;

    &__header {
        padding: 16px 0;
        text-align: right;
    }

    &__content {
        overflow: auto;
    }

    .legal-modal {
        p {
            @include mix.legal-paragraph;
        }
    }
}

.modal.modal--open {
    display: flex;

    &.trigger-transition {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
    }
}

.btn-close {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.01em;

    display: flex;
    align-items: center;

    padding: 0 4px 0 9px;
    margin: 0 -4px 0 auto;
    background: none;
    border: none;
    border-radius: 50px;

    line-height: 16px;

    transition: background 300ms ease-in-out;
    outline: none;

    &__icon {
        margin-left: 8px;

        rect {
            transition: fill 300ms ease-in-out;
        }
    }

    &:focus,
    &:hover {
        background: var.$grey-3;

        .btn-close__icon {
            rect {
                fill: var.$grey-3;
            }
        }
    }
}

@media (min-width: var.$breakpoint-rule-tablet) {
    .modal {
        top: 50%;
        bottom: auto;

        max-height: 90vh;
        width: 790px;
        padding: 40px 48px;

        border-radius: 16px;

        opacity: 0;
        transform: translateX(-50%) translateY(-25%);

        &__content {
            padding: #{64px - 48px};
        }
    }

    .modal.modal--open {

        &.trigger-transition {
            transform: translateX(-50%) translateY(-50%);
        }
    }
}
