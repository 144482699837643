@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

.main-footer-container {
    position: relative;
    background: var.$light-grey;
    z-index: 0;
}

.main-footer-container--white {
    background: var.$white;
}

.main-footer-wrapper {
    padding-top: 64px;
    padding-bottom: 64px;

    &__hr {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        height: 2px;
        background: var.$grey-3;
    }

    &__background-image {
        z-index: -1;
        position: absolute;
        bottom: 0;
        right: 0;
        background-position: 53px 12px;
        background-repeat: no-repeat;
        background-size: contain;
        width: 322px;
        height: 89px;
    }

}

.main-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__logo {
        width: 182px;
        height: auto;
        margin-bottom: 16px;
    }

    &__subtitle {
        text-align: center;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;

        margin-bottom: 32px;
    }

    &__footnote {
        text-align: center;
        @include mix.font-detail-regular;
        margin-bottom: 16px;
    }

    &__links {
        margin-bottom: 32px;
    }

    &__sponsor {
        color: var.$grey-1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: .8rem;
        margin: 1rem 0 1.5rem;

        a {
            display: block;
            margin-left: .5rem;

            svg {
                height: 1rem;
            }
        }
        
    }
}

.footer-links {
    &__item {
        text-align: center;
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__link {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: var.$black;
        transition: color 300ms ease-in-out;
        outline: none;

        &:hover,
        &:focus {
            color: var.$electric-blue;
        }
    }
}

.footer-links.footer-links--desktop {
    display: none;
}

@media (min-width: var.$breakpoint-rule-tablet) {
    .main-footer-wrapper {
        display: flex;

        &__hr {
            display: block;
        }
    }

    .main-footer {
        flex-grow: 1;
        display: inline-flex;
        align-items: flex-start;

        &__subtitle {
            text-align: left;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
            color: var.$black;
        }

        &__footnote {
            text-align: left;
            margin-bottom: 0;
        }

        &__links {
            display: none;
            order: 1;
        }

        &__sponsor {
            flex-direction: row;
        }

    }

    .footer-links {
        flex-grow: 1;
        margin-right: 40px;
        padding: 2px 2px 0 0;

        display: none;

        &__item {
            text-align: left;
        }
    }

    .footer-links.footer-links--desktop {
        display: inline-block;
    }
}

@media (min-width: var.$breakpoint-rule-desktop) {
    .main-footer-wrapper {
        &__background-image {
            bottom: 0;
            right: 38px;
            //left: 60.333%;
            background-position: center;
            width:  533px;
            height: 145px;
        }
    }

    .main-footer {
        &__sponsor {
            font-size: 1rem;

            a {
                svg {
                    height: 1.2rem;
                }
            }
        }
    }
}
