// Primary
$light-blue: #01C1FF;
$electric-blue: #1670E4;
$orange: #FE762A;

// Secondary
$light-orange: #FF9C41;
$green: #39D19E;
$red-error: #DE1947;


// Grey Scale
$black: #000000;
$grey-1: #6F6F6F;
$grey-2: #B7B7B7;
$grey-3: #E5E5E5;
$light-grey: #F6F6F6;
$white: #FFFFFF;

$sky-light: #E3E5E5;
$sky-dark: #B4B6B8;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px
);

$breakpoint-rule-gulliver: 320px;
$breakpoint-rule-tablet: map-get($grid-breakpoints, 'md');
$breakpoint-rule-desktop: map-get($grid-breakpoints, 'lg');
$breakpoint-rule-over-desktop: map-get($grid-breakpoints, 'xxl');

/*
* z-index de la aplicación
*/
$zindex-dropdown:          1000 !default;
$zindex-sticky:            1020 !default;
$zindex-fixed:             1030 !default;
$zindex-modal-backdrop:    1040 !default;
$zindex-modal:             1050 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;

$max-content-width: 1200px;
